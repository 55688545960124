<template>
  <div justify="center">
    <div cols="auto">
      <img src="../assets/autismo.png" alt="" height="15" width="15" />
      <img src="../assets/cadeira-rodas.png" alt="" height="15" width="15" />
      <img src="../assets/gravida.png" alt="" height="15" width="15" />
      <img src="../assets/mae-com-bebe.png" alt="" height="15" width="15" />
      <img src="../assets/velho.png" alt="" height="15" width="15" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PreferentialImages",
};
</script>
